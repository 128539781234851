
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { Base64 } from 'js-base64'
import { computed, defineComponent, defineAsyncComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

interface IOriginData {
  shortlink?: string
  random?: string,
  pv: string | number,
  uv: string | number,
  ip: string | number
}

interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  link_id: number,
  ip: string,
  area: string,
  os: string,
  browser: string,
  equip: string,
  from: string,
  createtime: string
}

interface ITopCard {
  icon: string,
  background: string,
  subtitle: string
}

interface IAccessData {
  ip: string,
  uv: string,
  pv: string
}

interface IAccessLineData {
  time: string,
  pv: number,
  ip: number,
  uv: number
}

interface IEquipSystem {
  name: string,
  value: number,
  icon: string,
  color: string,
  percentage?: number
}

interface IBrowerBarData {
  name: string,
  value: number
}

interface IDomesticData {
  name: string,
  value: string
}

interface ISupplier {
  name: string,
  value: number
}

interface IState {
  topCard: ITopCard[],
  radio1: string,
  activeName: string,
  page: number,
  pageSize: number,
  count: number,
  tableData: ITableData[],
  originData: IOriginData,
  pickerDate: (Date[] | Date)[],
  defaultTime: Array<any>,
  accessData: IAccessData[]
  accessLineData: IAccessLineData[],
  equipSystem: IEquipSystem[],
  browerBarData: IBrowerBarData[],
  domesticData: IDomesticData[],
  mapSlider: number,
  supplier: ISupplier[] | null
}

export default defineComponent({
  name: 'DataAnalysis',
  components: {
    AccessLineCharts: defineAsyncComponent(() => import('./maps/AccessLineCharts.vue')),
    DomesticMapCharts: defineAsyncComponent(() => import('./maps/DomesticMapCharts.vue')),
    DomesticBarCharts: defineAsyncComponent(() => import('./maps/DomesticBarCharts.vue')),
    // HourScatterCharts: defineAsyncComponent(() => import('./maps/HourScatterCharts.vue')),
    SystemPieCharts: defineAsyncComponent(() => import('./maps/SystemPieCharts.vue')),
    BrowerBarCharts: defineAsyncComponent(() => import('./maps/BrowerBarCharts.vue')),
    SupplierBarCharts: defineAsyncComponent(() => import('./maps/SupplierBarCharts.vue'))
  },
  setup () {
    const state = reactive<IState>({
      topCard: [
        {
          icon: 'el-icon-link',
          background: '#409EFF',
          subtitle: '短网址'
        },
        {
          icon: 'el-icon-star-off',
          background: '#67C23A',
          subtitle: '总访问量'
        },
        {
          icon: 'el-icon-monitor',
          background: '#E6A23C',
          subtitle: 'IP数'
        },
        {
          icon: 'el-icon-user',
          background: '#F56C6C',
          subtitle: '独立用户(UV)数'
        }
      ],
      radio1: '今天',
      activeName: 'first',
      page: 1,
      pageSize: 10,
      count: 0,
      tableData: [],
      originData: {
        ip: '0',
        uv: '0',
        pv: '0'
      },
      pickerDate: [
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        new Date(),
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ]
      ],
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ],
      accessData: [],
      accessLineData: [],
      equipSystem: [
        {
          name: 'Windows',
          icon: 'windows',
          value: 0,
          color: '#5470C6'
        },
        {
          name: 'Macintosh',
          icon: 'macos',
          value: 0,
          color: '#91CC75'
        },
        {
          name: '未知',
          icon: 'unknown',
          value: 0,
          color: '#FAC858'
        },
        {
          name: 'Linux',
          icon: 'linux',
          value: 0,
          color: '#EE6666'
        },
        {
          name: 'Android',
          icon: 'android',
          value: 0,
          color: '#73C0DE'
        },
        {
          name: 'iPhone',
          icon: 'iphone',
          value: 0,
          color: '#3BA272'
        },
        {
          name: 'iPad',
          icon: 'ipad',
          value: 0,
          color: '#FC8452'
        }
      ],
      browerBarData: [
        {
          name: 'Chrome',
          value: 8609
        },
        {
          name: 'Firefox',
          value: 980
        },
        {
          name: 'QQ浏览器',
          value: 669
        },
        {
          name: '搜狗浏览器',
          value: 556
        },
        {
          name: 'Safari',
          value: 481
        },
        {
          name: 'MSIE',
          value: 370
        },
        {
          name: '未知',
          value: 198
        },
        {
          name: '遨游浏览器',
          value: 197
        },
        {
          name: '三维浏览器',
          value: 174
        },
        {
          name: 'Edge',
          value: 165
        },
        {
          name: '微信内嵌浏览器',
          value: 111
        },
        {
          name: '猎豹浏览器',
          value: 87
        },
        {
          name: '其它',
          value: 604
        }
      ],
      domesticData: [],
      mapSlider: 0,
      supplier: [
        {
          name: '电信',
          value: 7665
        },
        {
          name: '联通',
          value: 2417
        },
        {
          name: '移动',
          value: 1427
        },
        {
          name: '鹏博士',
          value: 269
        },
        {
          name: '长城',
          value: 231
        },
        {
          name: '铁通',
          value: 741
        }
      ]
    })
    const stateAsRefs = toRefs(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)
    const { proxy }: any = getCurrentInstance()

    window.addEventListener('resize', () => resizeWidth())
    const resizeWidth = (): void => {
      const clientWidth = document.body.clientWidth
      if (clientWidth <= 768) {
        store.commit('SET_RESPONSIVELY_MODE', true)
        if (store.getters.GET_MENU_BAR) {
          store.commit('SET_MENU_BAR', false)
        }
      } else {
        store.commit('SET_RESPONSIVELY_MODE', false)
        if (store.getters.GET_MENU_BAR) {
          store.commit('SET_MENU_BAR', false)
        }
      }
    }
    resizeWidth()

    const getLocalTime = (nS: string): string => {
      const d = new Date(Number(nS) * 1000)
      const year = d.getFullYear() // 取得4位数的年份
      const month = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1) // 取得日期中的月份，其中0表示1月，11表示12月
      const date = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate() // 返回日期月份中的天数（1到31）
      const hour = d.getHours() <= 9 ? '0' + d.getHours() : d.getHours() // 返回日期中的小时数（0到23）
      const minute = d.getMinutes() <= 9 ? '0' + d.getMinutes() : d.getMinutes() // 返回日期中的分钟数（0到59）
      const second = d.getSeconds() <= 9 ? '0' + d.getSeconds() : d.getSeconds() // 返回日期中的秒数（0到59）
      return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
    }

    const getUrlKey = (name: string, url: string): any => {
      const value: string | undefined = (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [''])[1]
      if (value) {
        return decodeURIComponent(value.replace(/\+/g, '%20'))
      } else {
        return undefined
      }
    }

    const formatTimer = (val: Date): string => {
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return year + '-' + month + '-' + day
    }

    // 获取总数
    const getCount = (): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/count', {
        params: {
          random: state.originData.random
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.originData.ip = res.data.ip
          state.originData.pv = res.data.pv
          state.originData.uv = res.data.uv
        }
      })
    }

    const getAccessData = (time: string): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/count', {
        params: {
          random: state.originData.random,
          time: time
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.accessData = res.data
        }
      })
    }

    const getAccessLineCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/pvlog', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.accessLineData = res.data
        }
      })
    }

    // 访问者设备系统分析
    const getSystemPieCharts = (date: any): void => {
      if (!state.originData) return
      let total = 0
      proxy.$axios.get('/echarts/os', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.equipSystem.forEach((item: IEquipSystem) => {
            item.value = 0
          })
          if (res.data.length > 0) {
            res.data.forEach((item: IEquipSystem) => {
              state.equipSystem.forEach((item2: IEquipSystem) => {
                if (item2.name === item.name) {
                  item2.value = item.value
                }
              })
            })
          }
          state.equipSystem.forEach((item: IEquipSystem) => {
            total += item.value
          })
          state.equipSystem.forEach((item: IEquipSystem) => {
            item.percentage = Number(((item.value / total) * 100).toFixed(2))
          })
          state.equipSystem.sort((a: any, b: any) => {
            return b.percentage - a.percentage
          })
        }
      })
    }

    // 访问者浏览器统计
    const getBrowerBarCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/browser', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.browerBarData = res.data
        }
      })
    }

    // 国内访问地区统计
    const getDomesticCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/area', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.domesticData = res.data.sort((a: any, b: any) => {
            return b.value - a.value
          })
        }
      })
    }

    // 网络供应商统计
    const getSupplierBarCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/isp', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.supplier = res.data
        }
      })
      // state.supplier.sort((a: any, b: any) => {
      //   return a.value - b.value
      // })
    }

    // 记录明细
    const getTableList = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/log', {
        params: {
          page: state.page,
          page_size: state.pageSize,
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.count = res.data.count
          state.tableData = res.data.lists
        }
      })
    }

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList(state.pickerDate[6])
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList(state.pickerDate[6])
    }

    const init = (): void => {
      const item: IOriginData = JSON.parse(Base64.decode(getUrlKey('s', window.location.href)))
      if (item) {
        state.originData.shortlink = item.shortlink
        state.originData.random = item.random
        setTimeout(() => {
          getCount()
          getAccessData('today')
          getAccessLineCharts(state.pickerDate[0])
          getSystemPieCharts(state.pickerDate[2])
          getBrowerBarCharts(state.pickerDate[3])
          getDomesticCharts(state.pickerDate[4])
          getSupplierBarCharts(state.pickerDate[5])
          getTableList(state.pickerDate[6])
        }, 200)
      } else {
        ElMessage.error('数据缺失')
        setTimeout(() => {
          window.history.go(-1)
        }, 800)
      }
    }
    init()

    return {
      ...stateAsRefs,
      responsively,
      getAccessData,
      getAccessLineCharts,
      getSystemPieCharts,
      getBrowerBarCharts,
      getDomesticCharts,
      getSupplierBarCharts,
      handleSizeChange,
      handleCurrentChange,
      getLocalTime
    }
  }
})
