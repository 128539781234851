<template>
  <el-container>
    <el-main class="data-analysis">
      <el-alert
        v-if="responsively"
        title="提示"
        type="warning"
        description="为了您能更好的查看报表数据, 请选择使用大屏幕设备"
        show-icon
        effect="dark"
        :closable="false"
      >
      </el-alert>
      <el-row class="card-info" :gutter="24">
        <el-col v-for="(item, index) in topCard" :key="index" :xs="24" :sm="12" :lg="6">
          <el-card class="card">
            <div class="card-icon" :style="`background: ${ item.background }`">
              <em :class="item.icon"></em>
            </div>
            <div class="card-data">
              <el-tooltip class="item" effect="dark" v-if="originData && index === 0" :content="originData.shortlink" placement="bottom">
                <p class="header-title--link" v-if="originData && index === 0">{{ originData.shortlink }}</p>
              </el-tooltip>
              <p class="header-title" v-if="originData && index === 1">{{ originData.pv }}</p>
              <p class="header-title" v-if="originData && index === 2">{{ originData.ip }}</p>
              <p class="header-title" v-if="originData && index === 3">{{ originData.uv }}</p>
              <p class="header-subtitle">{{ item.subtitle }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-space wrap size="large" direction="vertical">
        <el-card class="main-card day-access-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>7天内的访问统计</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  @change="getAccessLineCharts"
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[0]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="defaultTime"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :xs="24" :span="6">
              <el-radio-group v-model="radio1">
                <el-radio-button label="今天" @click="getAccessData('today')"></el-radio-button>
                <el-radio-button label="昨天" @click="getAccessData('yesterday')"></el-radio-button>
                <el-radio-button label="7天" @click="getAccessData('week')"></el-radio-button>
              </el-radio-group>
              <ul v-if="accessData">
                <li>
                  <p class="day-access-card__label" style="color: #67C23A;">{{ accessData.pv }}</p>
                  <p>访问量</p>
                </li>
                <li>
                  <p class="day-access-card__label" style="color: #E6A23C;">{{ accessData.ip }}</p>
                  <p>IP数</p>
                </li>
                <li>
                  <p class="day-access-card__label" style="color: #F56C6C;">{{ accessData.uv }}</p>
                  <p>UV数</p>
                </li>
              </ul>
            </el-col>
            <el-col :xs="24" :span="18">
              <access-line-charts class="charts" :data="accessLineData"></access-line-charts>
            </el-col>
          </el-row>
        </el-card>
        <!-- <el-card class="main-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>24小时内访问统计</h4>
              </el-col>
              <el-col class="right" :sm="{ span: 8, offset: 6 }" :md="{ span: 6, offset: 8 }" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  size="mini"
                  v-model="pickerDate[1]"
                  type="date"
                  align="center"
                  placeholder="选择日期"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :span="24">
              <hour-scatter-charts class="charts"></hour-scatter-charts>
            </el-col>
          </el-row>
        </el-card> -->
        <el-card class="main-card system-pie-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>访问者设备系统分析</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  @change="getSystemPieCharts"
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[2]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="defaultTime"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :md="16" :span="24">
              <system-pie-charts :data="equipSystem" class="charts"></system-pie-charts>
            </el-col>
            <el-col :md="8" :span="24">
              <h4 class="progress-title">设备系统TOP5</h4>
              <div class="progress-group" v-for="(item, index) in equipSystem" :key="index">
                <div v-if="index < 5" class="progress-icon">
                  <img :src="`images/data-analysis/${ item.icon }.svg`" :alt="item.icon" />
                </div>
                <div v-if="index < 5" class="progress-wrap">
                  <span class="progress-text">{{ item.name }}</span>
                  <el-progress :color="item.color" :stroke-width="10" :percentage="item.percentage"></el-progress>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="main-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>访问者浏览器统计</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  @change="getBrowerBarCharts"
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[3]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="defaultTime"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :span="24">
              <brower-bar-charts :data="browerBarData" class="charts"></brower-bar-charts>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="main-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>国内访问地区统计</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  @change="getDomesticCharts"
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[4]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="defaultTime"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :md="8" :span="24">
              <domestic-bar-charts class="charts" :data="domesticData"></domestic-bar-charts>
            </el-col>
            <el-col :md="14" :span="22">
              <domestic-map-charts class="charts" :data="domesticData" :slider="mapSlider"></domestic-map-charts>
            </el-col>
            <el-col :md="2" :span="2" align="middle" style="align-self: center;">
              <el-slider
                v-model="mapSlider"
                vertical
                :min="1"
                :max="5"
                :step="1"
                :show-tooltip="false"
                height="200px"
              />
            </el-col>
          </el-row>
        </el-card>
        <el-card class="main-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>网络供应商统计</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  @change="getSupplierBarCharts"
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[5]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="defaultTime"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :span="24">
              <supplier-bar-charts :data="supplier" class="charts"></supplier-bar-charts>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="main-card">
          <template #header>
            <el-row>
              <el-col class="left" :span="10" :xs="24">
                <h4>访问记录明细</h4>
              </el-col>
              <el-col class="right" :span="10" :offset="4" :xs="{ span: 24, offset: 0 }">
                <el-date-picker
                  popper-class="data-analysis__picker"
                  size="mini"
                  v-model="pickerDate[6]"
                  type="daterange"
                  align="center"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="false"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :span="24">
              <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                  label="日期"
                  width="150"
                >
                  <template #default="scope">
                    <span>{{ getLocalTime(scope.row.createtime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="短链接"
                  align="center"
                  width="200"
                >
                  <template #default="scope">
                    <span>{{ scope.row.domain }}/{{ scope.row.random }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="link"
                  align="center"
                  label="目标链接"
                  width="240"
                >
                </el-table-column>
                <el-table-column
                  prop="equip"
                  label="设备"
                  width="80"
                >
                </el-table-column>
                <el-table-column
                  prop="os"
                  label="系统"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="browser"
                  label="浏览器"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="ip"
                  label="IP"
                  width="140"
                >
                </el-table-column>
                <el-table-column
                  prop="area"
                  label="地区"
                  width="180"
                >
                  <template #default="scope">
                    <span>{{ scope.row.province }} - {{ scope.row.city }} - {{ scope.row.isp }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="from"
                  label="访问来源"
                  align="center"
                  width="200"
                >
                </el-table-column>
                <el-table-column
                  label="独立用户"
                  width="160"
                  align="center"
                >
                  <template #default="scope">
                    <el-tag effect="dark" :type="scope.row.uv ? 'success' : 'danger'">
                      {{ scope.row.uv ? '是' : '否' }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="page__footer">
          <el-pagination
            background
            :small="responsively"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            :pager-count="responsively ? 4 : 11"
            :style="responsively ? 'margin: 0 auto;' : ''"
            :layout="responsively ? 'prev, pager, next' : 'total, prev, pager, next'"
            :total="count"
          />
        </el-card>
      </el-space>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { Base64 } from 'js-base64'
import { computed, defineComponent, defineAsyncComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

interface IOriginData {
  shortlink?: string
  random?: string,
  pv: string | number,
  uv: string | number,
  ip: string | number
}

interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  link_id: number,
  ip: string,
  area: string,
  os: string,
  browser: string,
  equip: string,
  from: string,
  createtime: string
}

interface ITopCard {
  icon: string,
  background: string,
  subtitle: string
}

interface IAccessData {
  ip: string,
  uv: string,
  pv: string
}

interface IAccessLineData {
  time: string,
  pv: number,
  ip: number,
  uv: number
}

interface IEquipSystem {
  name: string,
  value: number,
  icon: string,
  color: string,
  percentage?: number
}

interface IBrowerBarData {
  name: string,
  value: number
}

interface IDomesticData {
  name: string,
  value: string
}

interface ISupplier {
  name: string,
  value: number
}

interface IState {
  topCard: ITopCard[],
  radio1: string,
  activeName: string,
  page: number,
  pageSize: number,
  count: number,
  tableData: ITableData[],
  originData: IOriginData,
  pickerDate: (Date[] | Date)[],
  defaultTime: Array<any>,
  accessData: IAccessData[]
  accessLineData: IAccessLineData[],
  equipSystem: IEquipSystem[],
  browerBarData: IBrowerBarData[],
  domesticData: IDomesticData[],
  mapSlider: number,
  supplier: ISupplier[] | null
}

export default defineComponent({
  name: 'DataAnalysis',
  components: {
    AccessLineCharts: defineAsyncComponent(() => import('./maps/AccessLineCharts.vue')),
    DomesticMapCharts: defineAsyncComponent(() => import('./maps/DomesticMapCharts.vue')),
    DomesticBarCharts: defineAsyncComponent(() => import('./maps/DomesticBarCharts.vue')),
    // HourScatterCharts: defineAsyncComponent(() => import('./maps/HourScatterCharts.vue')),
    SystemPieCharts: defineAsyncComponent(() => import('./maps/SystemPieCharts.vue')),
    BrowerBarCharts: defineAsyncComponent(() => import('./maps/BrowerBarCharts.vue')),
    SupplierBarCharts: defineAsyncComponent(() => import('./maps/SupplierBarCharts.vue'))
  },
  setup () {
    const state = reactive<IState>({
      topCard: [
        {
          icon: 'el-icon-link',
          background: '#409EFF',
          subtitle: '短网址'
        },
        {
          icon: 'el-icon-star-off',
          background: '#67C23A',
          subtitle: '总访问量'
        },
        {
          icon: 'el-icon-monitor',
          background: '#E6A23C',
          subtitle: 'IP数'
        },
        {
          icon: 'el-icon-user',
          background: '#F56C6C',
          subtitle: '独立用户(UV)数'
        }
      ],
      radio1: '今天',
      activeName: 'first',
      page: 1,
      pageSize: 10,
      count: 0,
      tableData: [],
      originData: {
        ip: '0',
        uv: '0',
        pv: '0'
      },
      pickerDate: [
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        new Date(),
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ],
        [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date()
        ]
      ],
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ],
      accessData: [],
      accessLineData: [],
      equipSystem: [
        {
          name: 'Windows',
          icon: 'windows',
          value: 0,
          color: '#5470C6'
        },
        {
          name: 'Macintosh',
          icon: 'macos',
          value: 0,
          color: '#91CC75'
        },
        {
          name: '未知',
          icon: 'unknown',
          value: 0,
          color: '#FAC858'
        },
        {
          name: 'Linux',
          icon: 'linux',
          value: 0,
          color: '#EE6666'
        },
        {
          name: 'Android',
          icon: 'android',
          value: 0,
          color: '#73C0DE'
        },
        {
          name: 'iPhone',
          icon: 'iphone',
          value: 0,
          color: '#3BA272'
        },
        {
          name: 'iPad',
          icon: 'ipad',
          value: 0,
          color: '#FC8452'
        }
      ],
      browerBarData: [
        {
          name: 'Chrome',
          value: 8609
        },
        {
          name: 'Firefox',
          value: 980
        },
        {
          name: 'QQ浏览器',
          value: 669
        },
        {
          name: '搜狗浏览器',
          value: 556
        },
        {
          name: 'Safari',
          value: 481
        },
        {
          name: 'MSIE',
          value: 370
        },
        {
          name: '未知',
          value: 198
        },
        {
          name: '遨游浏览器',
          value: 197
        },
        {
          name: '三维浏览器',
          value: 174
        },
        {
          name: 'Edge',
          value: 165
        },
        {
          name: '微信内嵌浏览器',
          value: 111
        },
        {
          name: '猎豹浏览器',
          value: 87
        },
        {
          name: '其它',
          value: 604
        }
      ],
      domesticData: [],
      mapSlider: 0,
      supplier: [
        {
          name: '电信',
          value: 7665
        },
        {
          name: '联通',
          value: 2417
        },
        {
          name: '移动',
          value: 1427
        },
        {
          name: '鹏博士',
          value: 269
        },
        {
          name: '长城',
          value: 231
        },
        {
          name: '铁通',
          value: 741
        }
      ]
    })
    const stateAsRefs = toRefs(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)
    const { proxy }: any = getCurrentInstance()

    window.addEventListener('resize', () => resizeWidth())
    const resizeWidth = (): void => {
      const clientWidth = document.body.clientWidth
      if (clientWidth <= 768) {
        store.commit('SET_RESPONSIVELY_MODE', true)
        if (store.getters.GET_MENU_BAR) {
          store.commit('SET_MENU_BAR', false)
        }
      } else {
        store.commit('SET_RESPONSIVELY_MODE', false)
        if (store.getters.GET_MENU_BAR) {
          store.commit('SET_MENU_BAR', false)
        }
      }
    }
    resizeWidth()

    const getLocalTime = (nS: string): string => {
      const d = new Date(Number(nS) * 1000)
      const year = d.getFullYear() // 取得4位数的年份
      const month = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1) // 取得日期中的月份，其中0表示1月，11表示12月
      const date = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate() // 返回日期月份中的天数（1到31）
      const hour = d.getHours() <= 9 ? '0' + d.getHours() : d.getHours() // 返回日期中的小时数（0到23）
      const minute = d.getMinutes() <= 9 ? '0' + d.getMinutes() : d.getMinutes() // 返回日期中的分钟数（0到59）
      const second = d.getSeconds() <= 9 ? '0' + d.getSeconds() : d.getSeconds() // 返回日期中的秒数（0到59）
      return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
    }

    const getUrlKey = (name: string, url: string): any => {
      const value: string | undefined = (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [''])[1]
      if (value) {
        return decodeURIComponent(value.replace(/\+/g, '%20'))
      } else {
        return undefined
      }
    }

    const formatTimer = (val: Date): string => {
      const date = new Date(val)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return year + '-' + month + '-' + day
    }

    // 获取总数
    const getCount = (): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/count', {
        params: {
          random: state.originData.random
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.originData.ip = res.data.ip
          state.originData.pv = res.data.pv
          state.originData.uv = res.data.uv
        }
      })
    }

    const getAccessData = (time: string): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/count', {
        params: {
          random: state.originData.random,
          time: time
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.accessData = res.data
        }
      })
    }

    const getAccessLineCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/pvlog', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.accessLineData = res.data
        }
      })
    }

    // 访问者设备系统分析
    const getSystemPieCharts = (date: any): void => {
      if (!state.originData) return
      let total = 0
      proxy.$axios.get('/echarts/os', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.equipSystem.forEach((item: IEquipSystem) => {
            item.value = 0
          })
          if (res.data.length > 0) {
            res.data.forEach((item: IEquipSystem) => {
              state.equipSystem.forEach((item2: IEquipSystem) => {
                if (item2.name === item.name) {
                  item2.value = item.value
                }
              })
            })
          }
          state.equipSystem.forEach((item: IEquipSystem) => {
            total += item.value
          })
          state.equipSystem.forEach((item: IEquipSystem) => {
            item.percentage = Number(((item.value / total) * 100).toFixed(2))
          })
          state.equipSystem.sort((a: any, b: any) => {
            return b.percentage - a.percentage
          })
        }
      })
    }

    // 访问者浏览器统计
    const getBrowerBarCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/browser', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.browerBarData = res.data
        }
      })
    }

    // 国内访问地区统计
    const getDomesticCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/area', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.domesticData = res.data.sort((a: any, b: any) => {
            return b.value - a.value
          })
        }
      })
    }

    // 网络供应商统计
    const getSupplierBarCharts = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/isp', {
        params: {
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.supplier = res.data
        }
      })
      // state.supplier.sort((a: any, b: any) => {
      //   return a.value - b.value
      // })
    }

    // 记录明细
    const getTableList = (date: any): void => {
      if (!state.originData) return
      proxy.$axios.get('/echarts/log', {
        params: {
          page: state.page,
          page_size: state.pageSize,
          random: state.originData.random,
          time: formatTimer(date[0]) + ' - ' + formatTimer(date[1])
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          state.count = res.data.count
          state.tableData = res.data.lists
        }
      })
    }

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList(state.pickerDate[6])
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList(state.pickerDate[6])
    }

    const init = (): void => {
      const item: IOriginData = JSON.parse(Base64.decode(getUrlKey('s', window.location.href)))
      if (item) {
        state.originData.shortlink = item.shortlink
        state.originData.random = item.random
        setTimeout(() => {
          getCount()
          getAccessData('today')
          getAccessLineCharts(state.pickerDate[0])
          getSystemPieCharts(state.pickerDate[2])
          getBrowerBarCharts(state.pickerDate[3])
          getDomesticCharts(state.pickerDate[4])
          getSupplierBarCharts(state.pickerDate[5])
          getTableList(state.pickerDate[6])
        }, 200)
      } else {
        ElMessage.error('数据缺失')
        setTimeout(() => {
          window.history.go(-1)
        }, 800)
      }
    }
    init()

    return {
      ...stateAsRefs,
      responsively,
      getAccessData,
      getAccessLineCharts,
      getSystemPieCharts,
      getBrowerBarCharts,
      getDomesticCharts,
      getSupplierBarCharts,
      handleSizeChange,
      handleCurrentChange,
      getLocalTime
    }
  }
})
</script>

<style lang="scss" scoped>
  .mobile {
    padding: 0;
    :deep(.el-card) {
      border-radius: 0 !important;
      height: 100% !important;
    }
  }
  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__header {
      margin: 0 0 12px;
      :deep(.el-select .el-input) {
        width: 100px;
      }
      :deep(.el-pagination__sizes) {
        margin-right: 0;
      }
      @include cardBody;
      :deep(.el-input) {
        display: inline-flex;
      }
      :deep(.el-input-group__append) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__data {
      height: calc(100% - 124px);
      :deep(.el-card) {
        height: 100%;
      }
      :deep(.el-card__body) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        em {
          color: #E6A23C;
          font-size: 90px;
          margin-bottom: 24px;
        }
        h1 {
          font-size: 18px;
          color: #909399;
        }
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }
  .card {
    overflow: hidden;
    :deep(.el-card__body) {
      display: flex;
      padding: 0;
      height: 84px;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
    }
    &-info {
      max-width: 1224px;
      @media screen and (min-width: 1240px) {
        margin: 0 auto !important;
      }
      .el-col {
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
    &-icon {
      color: #FFF;
      font-size: 24px;
      text-align: center;
      width: 25%;
      height: 100%;
      line-height: 84px;
    }
    &-data {
      width: 65%;
      height: 100%;
      box-sizing: border-box;
      padding: 14px 8px 14px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .header {
      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        font-size: 24px;
        &--link{
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
          font-size: 16px;
        }
      }
      &-subtitle {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
  .el-container {
    width: 100%;
    height: 100vh;
  }
  .data-analysis {
    background: #EEE;
    :deep(.el-card) {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      border: none !important;
      // height: calc(100% - 42px);
    }
  }
  .header-title {
    line-height: 1.6;
  }
  .charts {
    margin: 12px 0 !important;
  }
  .el-space, :deep(.el-space__item) {
    width: 100%;
  }
  .main-card {
    .left, .right {
      padding: 6px 0;
    }
  }
  :deep(.el-date-editor.el-input__inner) {
    width: 100%;
  }
  :deep(.el-alert__title) {
    font-size: 18px;
    line-height: 1.4;
  }
  :deep(.el-alert__description) {
    font-size: 14px;
  }
  .day-access-card {
    &__label {
      font-size: 40px;
    }
    :deep(.el-radio-group) {
      display: block;
      text-align: center;
    }
    ul {
      li {
        text-align: center;
        padding: 25px 0;
        border-radius: 3px;
        &:hover {
          text-decoration: none;
          background: #EEE;
        }
        .el-radio-button__inner {
          width: 100%;
        }
      }
    }
  }
  .system-pie-card {
    .progress {
      &-group {
        display: flex;
        align-items: center;
        margin: 20px 0;
        @media screen and (max-width: 991px) {
          padding: 0 50px;
        }
        @media screen and (max-width: 700px) {
          padding: 0;
        }
      }
      &-title {
        text-align: center;
      }
      &-wrap {
        flex: 0 0 calc(100% - 96px);
        @media screen and (max-width: 700px) {
          flex: 0 0 calc(100% - 70px);
        }
      }
      &-text {
        line-height: 32px;
      }
      &-icon {
        width: 64px;
        height: 80px;
        flex: 0 0 96px;
        @media screen and (max-width: 700px) {
          width: 32px;
          height: 40px;
          flex: 0 0 70px;
        }
        img {
          display: block;
          margin: 8px auto;
          width: 64px;
          height: 64px;
          @media screen and (max-width: 700px) {
            margin: 4px auto;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  @media screen and (max-width: 767px) {
    .data-analysis__picker {
      .el-date-range-picker {
        width: 280px;
      }
      .el-picker-panel {
        &__body {
          width: 100%;
          min-width: 0;
          display: flex;
          flex-direction: column;
        }
        &__content {
          width: 100%;
        }
      }
    }
  }
</style>
